<template>
  <test-section :post="post" />
</template>
<script>
import BigCard from '@/components/BigCard.vue';
import SmallCard from '@/components/SmallCard.vue';
import TestSection from '@/components/TestSection.vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'FocusedView',
  // eslint-disable-next-line vue/no-unused-components
  components: { TestSection, BigCard, SmallCard },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const content = {
      Notes: {
        title: 'ChiroWrite Notes',
        photo: 'chiropractor-happy',
        alt: 'Happy chiropractor with arms folded',
        overview:
          'ChiroWrite is designed for use on touch screen devices such as tablets and touch screen monitors. While a touch screen or Tablet PC is not necessary to use ChiroWrite, their presence will enhance the overall usage of the EHR system. Chirowrite has been designed to be HIPAA compliant. ',
        overview2:
          'An easy to use interface utilizing efficient screen layout, flow and navigation boxes will ensure that the time consuming task of writing that the doctor has to do, is greatly reduced.',
        features: [
          {
            icon: 'worxflow-engine',
            feature: 'Worxflow Engine',
            description: 'Chirowrite’s own workflow engine, allows doctors to configure the order of the screens of an exam or SOAP visit. You can also have an unlimited amount of worxflows. Meaning you can specify different worxflows for different types of patients. Only see screens that you need, saving you time on your visit.',
          },
          {
            icon: 'worxflow-engine',
            feature: 'Worxphrase Engine',
            description: 'Chirowrite’s own worxphrase engine allows you to configure standard sentences or paragraphs that you use often, store them, and insert them into notes as you see fit.',
          },
          {
            icon: 'copy-notes',
            feature: 'Copy prior visit information',
            description: 'Chirowrite automatically copies information from the prior visit into the current note, all you have to do is make the necessary updates, and you’re done.',
          },
          {
            icon: 'picture-camera',
            feature: 'Include patient images',
            description: 'Images such as x-rays, drivers license, insurance cards, and much more can be either scanned or imported into the patient travel card. There you have access to all imported images and scans at a glance.',
          },
          {
            icon: 'customize-screen',
            feature: 'Customizeable screens',
            description: 'Chirowrite allows you to make special screens for SOAP notes or exams. These screens allow you to design your own questions to be asked and answered that will be included on the notes.',
          },
          {
            icon: 'take-notes',
            feature: 'Customizable reports',
            description: 'The text in the reports are customizable. If you want to add a bit more of your own style to a report, or if you don’t like the verbiage then you can alter it. Reports have been divided into simple sections and sub-sections to make navigating the report easy.',
          },
        ],
        productPhoto: 'patientNotes',
        ppAlt: 'Example screen of Chirowrite notes.',
      },
      Scheduler: {
        title: 'ChiroWrite Scheduler',
        photo: 'chiro-office-schedule',
        alt: 'Scheduler at chiropractors office',
        overview:
          'The ChiroWrite Scheduler provides staff with complete scheduling capabilities to run your office in an effective and efficient manner. Multiple appointment types can be defined by the office each with their own length of time. The multi-view interface allows a wide array of scheduling options. Staff can view one provider or multiple providers, one day or multiple days.  When scheduling patients, multiple appointments can be scheduled at one time. Patient check-out is simplified to a single screen allowing fast and easy completion. Integrated credit card processing helps speed up checkout and simplifies operations. ',
        overview2:
          'Practice management reports can be generated to provide office statistics from appointment counts to money management.',
        features: [
          {
            icon: 'chat-box',
            feature: 'Text/Email reminders',
            description: 'Staff can setup text or email reminders for the patient so that they automatically receive a reminder for their appointment. There is also the ability to send individual messages to patients or group messages to many patients at once.',
          },
          {
            icon: 'view-schedule',
            feature: 'View schedule for single or multiple doctors and single or multiple days',
            description: 'The schedule can be viewed for one doctor or multiple doctors at a time. It can also be viewed based on different services the office may setup. The schedule may also be viewed for one day at a time or multiple days at a time.',
          },
          {
            icon: 'calendar',
            feature: 'Schedule recurring appointments',
            description: 'While making appointment, users can easily create recurring appointments or copy one appointment to other future dates. This makes it easy to schedule a patient for the same time three days a week if needed.',
          },
          {
            icon: 'notes-saved',
            feature: 'Add walk-in patients easily',
            description: 'Staff can easily create new patient files and add the new patient to the schedule.',
          },
          {
            icon: 'payment-processing',
            feature: 'Inventory & scan checkout',
            description: 'The system allows for inventory items to be added and tracked. Thresholds can be set for reorder points to allow for easy inventory management. Inventory items that have a barcode can be input into the system so that check out can be done like in a grocery store by scanning the items. This saves time if you sell a lot of inventory.',
          },
          {
            icon: 'payment-option',
            feature: 'Generate receipts for patients',
            description: 'Receipts that are generated for patients have all the necessary information to be considered a superbill. This could include items such as office information, patient information, prices, tax, provider information, billing codes and diagnosis codes. Receipts can be for one date of service or multiple dates such as an end of year listing.',
          },
          {
            icon: 'payment-processing',
            feature: 'Process miscellaneous purchases',
            description: 'Purchases can be handled without an appointment, allowing offices to sell products to patients or non patients at any time.',
          },
          {
            icon: 'document-list',
            feature: 'Customizable fee profiles',
            description: 'Different fee profiles can be created if different pricing structures are required. For example there may be a standard fee profile containing your normal prices and a second profile that allows for “Time-of-Service” discounts. This might include service prices or product prices.',
          },
          {
            icon: 'team-work',
            feature: 'Customizable for your office needs',
            description: 'Patients come to the office for different reasons depending on the types of services that are provided. Appointment types can be created in the scheduler with different colors and different lengths of time to see at a glance services that are being provided during specific times. The schedule can be setup or 5,10,15,20 or 30 minute increments.',
          },
          {
            icon: 'print-option',
            feature: 'Generate management reports',
            description: 'Reports can be generated so the user can view statistical information regarding their practice. Appointment statistics such as new patient appointments, cancelled appointments, completed appointments, and missed appointments can be consolidated into printable reports. Balance reports can also be generated to keep track of payment information. Totals can be collected by type such as cash, visa, mastercard, amex, etc. Other reports can show totals by CPT/billing codes.',
          },
        ],
        productPhoto: 'scheduler-default',
        ppAlt: 'Example image of the scheduler screen.',
      },
      Billing: {
        title: 'ChiroWrite Billing',
        photo: 'chiro-office-biller',
        alt: 'Biller at Chiropractic office',
        overview:
          'The ChiroWrite billing functionality provides complete billing services. From claims creation, to generation of compliant EDI 5010 files or printouts of the CMS1500 form, to inputting of insurance checks and EOB’s the ChiroWrite system covers it. Set up parameters by insurance company to ensure complete compliance with that insurance companies needs. ',
        overview2:
          'Accounts receivable can be viewed through reports or through our online summary drill-down.',
        features: [
          {
            icon: 'notes-saved',
            feature: 'Create Claims',
            description: 'Easily create claims for all patients or a single patient. Use date ranges to limit claims to just those ready for processing. Generated claims are scrubbed for errors and errors reported to they can be fixed before sending out.',
          },
          {
            icon: 'cloud-save',
            feature: 'Creating ANSI 837/5010 files for upload to a clearinghouse',
            description: 'Claims can be generated into s standard ANSI 837 file for uploading to a clearinghouse. Our file will work with any clearinghouse so there is no need to switch from what you may be currently using.',
          },
          {
            icon: 'print-option',
            feature: 'Creating CMS/HCFA 1500 forms for printing',
            description: 'Claims can be printed onto the CMS/HCFA 1500 form. Users can either print with our embedded form or directly onto the standard “Red” forms. Our embedded form can be saved as a PDF for situations where the insurance company allows you to send them by email.',
          },
          {
            icon: 'insurance-payment',
            feature: 'Enter payments received from insurance companies',
            description: 'Payments received by the office can be entered into the system so as to keep track of what has been received.',
          },
          {
            icon: 'insurance-document',
            feature: 'Ability to keep track of claims that funds from checks were distributed',
            description: 'Checks that have been distributed can be viewed to show what claims and patients the payment went to.',
          },
          {
            icon: 'distribute-funds',
            feature: 'Distribute funds to claim, and the ability to assign leftover balances to current insurance, secondary insurance, the patient or to write-off the balance.',
            description: 'Payments can be distributed to claims through our claim payments screen. Selecting a check will show all outstanding claims for that payer allowing for rapid entry into claims. Line items with leftover monies can be allocated to the patient, current or secondary insurance or written off.',
          },
          {
            icon: 'account-receivable',
            feature: 'Accounts Receivable',
            description: 'Track accounts receivable by payer by timeframes such as 30/60/90/120 days past due. Use our drill down feature to see what patients make up these balances.',
          },
        ],
        productPhoto: 'scheduler-text',
        ppAlt: 'Example image of the billing screen',
      },
    };

    const post = computed(() => content[route.params.id]);

    if (!post.value) {
      router.push('/');
    }

    return {
      post,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #B4D2BA;
  @media(min-width: 700px ) {
    min-height: 400px;
    max-height: 400px;
    flex-direction: row;
  }

  .left {
    display: flex;
    flex: 4;
    order: 1;
    max-width: 50%;
    padding-left: 50px;
    padding-top: 75px;
  }

  .right{
    order: 2;
    flex: 3;
    height: 500px;
  }

  img {
    display: block;
    padding-top: 125px;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px (0,0,0,0.06);
  }
}

.main-section {
  display: flex;
  width: 100%;
  min-height: 600px;
  padding-top: 100px;
  background-color: green;
}
</style>
