<template>
  <footer>
    <div class="container">
      <div class="left">
        <div class="col-1">
          <router-link class="header" to="#">Chirowrite</router-link>
          <ul>
            <li>
            </li>
          </ul>
        </div>
        <div class="col-2">
          <ul>
            <router-link class="link" :to="{ name: 'home'}">Home</router-link>
            <router-link
              class="link"
              :to="{ name: 'FocusedView', params: { id: 'Notes' } }">
              Notes
            </router-link>
            <router-link
              class="link"
              :to="{ name: 'FocusedView', params: { id: 'Scheduler' } }">
              Scheduler
            </router-link>
            <router-link
              class="link"
              :to="{ name: 'FocusedView', params: { id: 'Billing' } }">
              Billing
            </router-link>
            <router-link
              class="link"
              :to="{ name: 'PricingView' }">
              Pricing
            </router-link>
            <router-link
              class="link"
              :to="{ name: 'AboutView' }">
              About Us
            </router-link>
            <router-link
              class="link"
              :to="{ name: 'ScheduleDemo' }">
              Contact
            </router-link>
          </ul>
        </div>
      </div>
      <div class="right">
        <p>Copyright 2022 All Rights Reserved</p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  footer {
    margin-top: auto;
    padding: 50px 25px;
    background-color: #e6e6e6;

    .container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      @media(min-width: 800px) {
        flex-direction: row;
        gap: 0px;
      }

      > div {
          display: flex;
          flex: 1;
      }

      .left {
        gap: 32px;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(min-width: 800px) {
          flex-direction: row;
          align-items: initial;
          gap: 0;
        }

        .header {
          font-size: 12px;
          color: #312f2f;
          text-decoration: none;
          font-weight: 700;
          @media(min-width: 800px) {
            text-align: center;
          }
        }

        ul {
          gap: 16px;
          list-style: none;
          display: flex;
        }

        .col-1,
        .col-2 {
          gap: 32px;
          display: flex;
          flex: 1;
          @media(min-width: 800px) {
            gap: 0;
          }
        }

        .col-1 {
          flex-direction: column;

          h2 {
            text-align: center;
            @media(min-width: 800px) {
              text-align: initial;
            }
          }

          ul {
            margin-top: auto;

            li {
              display: flex;
              align-items: center;

              .svg-icon {
                width: 24px;
                height: auto;
                color: #312f2f;
              }
            }
          }
        }

        .col-2 {
          ul {
            height: 100%;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            @media(min-width: 800px) {
              flex-direction: column;
            }

            .link {
              font-size: 12px;
              font-weight: 500;
              color: #312f2f;
              text-decoration: none;
            }
          }
        }
      }

      .right {
        gap: 32px;
        color: #312f2f;
        font-size: 14px;
        align-items: center;
        flex-direction: column;
        @media(min-width: 800px) {
          align-items: flex-end;
          gap: 0;
        }
      }

      p {
        margin-top: auto;
      }
    }
  }
</style>
