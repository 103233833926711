<template>
  <div class="wrapper">
    <div class="video-content">
        <video width="740" autoplay loop muted id="Chirowrite Company Video">
          <source src="@/assets/videos/Chirowrite-About-Movie.mp4" type="video/MP4">
          <track default
            kind="captions"
            srclang="en"
            src="#" />
            Sorry, your browser doesn't support embedded videos.
        </video>
    </div>
    <div class="main-text">
        ChiroWrite started offering chiropractors an effective and efficient suite of products
        in 2006. Building from that base with hundreds of suggestions from users over the years,
        ChiroWrite now offers chiropractors a variety of solutions to meet their software needs.
        Our front-to-back solutions are available running in house as a desktop application or in
        the cloud. Our modules include scheduling, billing, documentation and patient check-in.
        <br><br>
        Documentation Suite – Only need documentation?  Our software interfaces with many of the
        standard chiropractic billing systems such as Eclipse, Medisoft, InPhase and many others.
        <br><br>
        Cash Based Practice Suite – Have a cash only practice?  Get scheduling, documentation and
        our patient check-in to run your whole office from front to back.
        <br>
        Billing Practice Suite  – Doing internal billing?  Get scheduling, billing, documentation
        and our patient check-in.
    </div>
  </div>
  <div class="spacing"></div>
</template>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-content {
    display: flex;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    width: 320px;
    height: 210px;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media(min-width: 700px) {
      width: 600px;
      height: 400px;
    }
    @media(min-width: 1000px) {
      width: 800px;
      height: 500px;
    }

}

video {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.main-text {
    width: 60%;
    line-height: 1.7;
    height: auto;
    padding-top: 50px;
    text-align: left;
}
</style>
