<template>
  <div class="section-title">
    Pricing
  </div>
  <div class="page-heading">
    We have the right system for your practice
  </div>
  <div class="center_button">
    <button @click="toggleStuff">
      <div class="link">
        {{ buttonInput }}
      <img
        src="@/assets/Icons/user-crown-light.svg"
        alt="Crown"
        class="arrow"
      />
      </div>
    </button>
  </div>
  <div class="wrapper">
    <div class="card">
      <div class="card-description">
        <div class="heading">
          Chiro Core
        </div>
        <div class="sub-heading">
          Documentation Only<br>
          <span class="sub-text">Perfect for the office that only needs documentation.</span>
        </div>
      </div>
      <div class="card-pricing">
        <div class="split-left" v-show="purchase">
          <div class="price">
            $ 2,495 + $ 34mo
          </div>
          purchase
        </div>
        <div class="split-left" v-show="!purchase">
          <div class="price">
            $ 129
          </div>
          lease per month
        </div>
      </div>
      <div class="card-spacing"></div>
      <div class="card-body">
        <ul>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">SOAP Notes</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Exam Narratives.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Touch screen ready.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Pre loaded templates.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Easy to use interface.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Document scanning.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Interfaces with many different billing systems.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Unlimited doctors or providers.</span>
          </li>
        </ul>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="card">
      <div class="card-description">
        <div class="heading">
          Chiro Plus
        </div>
        <div class="sub-heading">
          Documentation & Scheduler<br>
          <span class="sub-text">Perfect for the cash based office.</span>
        </div>
      </div>
      <div class="card-pricing">
        <div class="split-left" v-show="purchase">
          <div class="price">
            $ 3,495 + $ 44mo
          </div>
          purchase
        </div>
        <div class="split-left" v-show="!purchase">
          <div class="price">
            $ 149
          </div>
          lease per month
        </div>
      </div>
      <div class="card-spacing"></div>
      <div class="card-body">
        <ul>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">All the features of Chiro Core and:</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Scheduling</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Patient ledger</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Basic text/email reminders using email method.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">View schedule for single or multiple doctors.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">View schedule for single or multiple days.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Generate receipts/superbills for patients.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Create membership plans.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Management statistical reports.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Credit card processing with X-Charge.</span>
          </li>
        </ul>
        <div class="card-footer"></div>
      </div>
    </div>
    <div class="card">
      <div class="card-description">
        <div class="heading">
          Chiro Suite
        </div>
        <div class="sub-heading">
          Documentation, Scheduler & Billing<br>
          <span class="sub-text">Perfect for the office doing insurance billing.</span>
        </div>
      </div>
      <div class="card-pricing">
        <div class="split-left" v-show="purchase">
          <div class="price">
            $ 4,495 + $ 49mo
          </div>
          purchase
        </div>
        <div class="split-left" v-show="!purchase">
          <div class="price">
            $ 179
          </div>
          lease per month
        </div>
      </div>
      <div class="card-spacing"></div>
      <div class="card-body">
        <ul>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">All the features of Chiro Plus and: </span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Insurance billing.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Print/email CMS 1500 forms.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">5010 files work with any clearinghouse.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Claims checked for errors before submission.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Line item posting of checks.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Account receivable reports.</span>
          </li>
          <li>
            <img src="@/assets/Icons/check-mark.svg" alt="check mark" />
            <span class="ul-format">Customizable insurance companies parameters.</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="card-spacer"></div>
  <div class="spacing"></div>
</template>

<script>
export default {
  data() {
    return {
      purchase: true,
      buttonInput: null,
      purchasePrice: 'Purchase Price',
      leasePrice: 'Lease Price',
    };
  },
  created() {
    this.buttonInput = this.leasePrice;
  },

  methods: {
    toggleStuff() {
      this.purchase = !this.purchase;
      if (this.buttonInput === this.leasePrice) {
        this.buttonInput = this.purchasePrice;
      } else {
        this.buttonInput = this.leasePrice;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  button {
    padding: 0;
    border: none;
    background: none;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 40px;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    font-weight: 500;
    height: auto;
    @media(min-width: 1125px) {
      flex-direction: row;
    }
    @media(min-width: 700px) {
      width: auto;
      max-width: 1200px;
      height: auto;
      margin: 0 auto;
      flex-direction: row;
      font-size: 28px;
      font-weight: 700;
    }
  }

  .section-title {
  font-family: 'Inconsolata', monospace;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 4;
  order: 2;
  font-family: 'Oxygen', sans-serif;
  font-size: 36px;
  padding-top: 40px;
  @media (min-width: 700px) {
    order: 1;
  }
  @media (min-width: 800px) {
    flex: 3;
  }
}

  .page-heading {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-weight: 600;
    font-size: 24px;
    color: #545353;
    justify-content: center;
    text-align: center;
  }

  .d-button {
  transition: 1s ease-in-out all;
  width: 130px;
  height: 50px;
  border-radius: 15px;
  background-color: #312F2F;
  border: .5px solid #c7cedb;
  color: #c7cedb;
  font-size: 14px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #c7cedb;
    color: #312F2F;
    border: .5px solid #312F2F;
  }
}

  .card {
    width: 325px;
    height: 600px;
    padding-bottom: 40px;
    margin: 0 auto;
    -webkit-box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.36);
    box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.36);
    @media(min-width: 700px) {
    width: 375px;
    height: 600px;
    }
  }

  .card-header {
    width: 325px;
    height: 25px;
    background-color: #fff;
    padding: 20px;
    text-align: right;
    @media(min-width: 700px) {
    width: 375px;
    height: 50px;
    }
    img {
      height: 25px;
      width: auto;
      opacity: 50%;
    }
  }

  .card-description {
    width: 325px;
    height: 125px;
    background-color: #fff;
    padding: 25px;
    line-height: 1.8;
    text-align: center;
    color: #01871D;
    @media(min-width: 700px) {
      width: 375px;
      height: 125px;
    }
  }

  .card-pricing {
    width: 325px;
    height: 65px;
    display: flex;
    background-color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding-top: 25px;
    @media(min-width: 700px) {
      width: 375px;
      height: 85px;
      font-size: 16px;
      font-weight: 500;
    }

    .split-left {
      width: 325px;
      height: 60px;
      text-align: center;
      padding-top: 5px;
      @media(min-width: 700px) {
        width: 375px;
      }
    }

    .split-right {
      width: 162.5px;
      height: 60px;
      text-align: center;
      padding-top: 5px;
      @media(min-width: 700px) {
        width: 187.5px;
      }
    }

    .price {
      font-size: 16px;
      font-weight: 500;
      color: #015D87;
      @media(min-width: 700px) {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .card-body {
    width: 325px;
    height: 215px;
    padding: 15px;
    background-color: #fff;
    font-size: 6px;
    line-height: 1.1;
    font-weight: 400;
    @media(min-width: 700px) {
    width: 375px;
    height: 300px;
    font-size: 18px;
    line-height: 1.5;
    }
    img {
      margin-top: 5px;
      padding-top: 10px;
      height: 20px;
      width: 20px;
    }
    ul {
      list-style: none;
    }
  }

  .main-text {
    padding-top: 10px;
  }
  .sub-text {
    font-size: 12px;
    font-weight: 400;
    @media(min-width: 700px) {
      font-size: 14px;
    }
  }
  .heading {
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    @media(min-width: 700px) {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .sub-heading {
    font-size: 12px;
    font-weight: 400;
    color: #57bceb;
    @media(min-width: 700px) {
      font-size: 16px;
      font-weight: 500
    }
  }
  .card-spacer {
    padding-top: 25px;
  }
  .ul-format {
    padding-left: 10px;
    font-size: 10px;
    @media(min-width: 700px) {
      font-size: 14px;
    }
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 32px;
    padding-bottom: 4px;
    border-bottom: 1px solid transparent;
    transition: .5s ease-in all;

    &:hover {
      // border-bottom-color: #015D87;
      color: green;
      scale: (1.02);
    }
  }

  .link-light {
    &:hover {
      border-bottom-color: #fff;
    }
  }
  .add-on-section {
    width: 500px;
    height: auto;
    font-size: 14px;

    .card-header {
      text-align: left;
      font-size: 24px;
    }
    .add-on-description {
      text-align: left;
      color: #01871D;
      padding-top: 20px;
      padding-bottom: 8px;
    }
    .add-on-price {
      font-size: 14px;
      font-weight: 400;
    }
    .add-on-features {
      font-size: 12px;
      padding-top: 10px;
      padding-left: 10px;
    }
  }
</style>
