<template>
  <header>
    <router-link :to="{ name: 'home'}">
      <img
        src="@/assets/images/chirowrite-logo-white.png"
        class="header"
        alt="Chirowrite Logo"/>
    </router-link>
      <div class="nav-links">
        <ul class="nav-links" v-show="!mobile">
          <router-link
            class="link"
            :to="{ name: 'FocusedView', params: { id: 'Notes' } }">
            Notes
          </router-link>
          <router-link
            class="link"
            :to="{ name: 'FocusedView', params: { id: 'Scheduler' } }">
            Scheduler
          </router-link>
          <router-link
            class="link"
            :to="{ name: 'FocusedView', params: { id: 'Billing' } }">
            Billing
          </router-link>
          <router-link
            class="link"
            :to="{ name: 'PricingView' }">
            Pricing
          </router-link>
          <router-link
          class="link"
          :to="{ name: 'AboutView' }">
          About Us
        </router-link>
        <router-link
          class="link"
          :to="{ name: 'ScheduleDemo' }">
          Contact
        </router-link>
        </ul>
      </div>
      <img src="@/assets/Icons/bars-regular.svg"
      class="nav-menu"
      @click="toggleMobileNav"
      @keypress="toggleMobileNav"
      v-show="mobile"
      alt="Menu hamburger"
      />
      <transition name="nav">
        <ul class="mobile-nav" v-show="mobileNav">
          <router-link
            class="link"
            :to="{ name: 'FocusedView', params: { id: 'Notes' } }"
            @click="toggleMobileNav"
            >
            Notes
          </router-link>
          <router-link
            class="link"
            :to="{ name: 'FocusedView', params: { id: 'Scheduler' } }"
            @click="toggleMobileNav"
            >
            Scheduler
          </router-link>
          <router-link
            class="link"
            :to="{ name: 'FocusedView', params: { id: 'Billing' } }"
            @click="toggleMobileNav"
            >
            Billing
          </router-link>
          <router-link
            class="link"
            :to="{ name: 'PricingView' }"
            @click="toggleMobileNav"
            >
            Pricing
          </router-link>
          <router-link
            class="link"
            :to="{ name: 'AboutView' }"
            @click="toggleMobileNav"
            >
            About Us
          </router-link>
          <router-link
            class="link"
            :to="{ name: 'ScheduleDemo' }"
            @click="toggleMobileNav"
            >
            Contact
          </router-link>
        </ul>
      </transition>
  </header>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      mobile: null,
      mobileNav: null,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
    },
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background-color: #312F2F;
  color: #E6E6E6;
  font-family: 'Quicksand', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  z-index: 99;
  height: 44px;

  .header {
    height: 25px;
  }

  .link {
    font-size: 14px;
    font-weight: 400;
    padding: 0 8px;
    color: #c7cedb;
    transition: transform 1s ease;

    &:hover {
      border-bottom: .5px solid #297373;
      font-weight: 600;
    }
  }

  .nav-links {
    display: flex;
    list-style: none;
    padding-left: 20px;
    padding-top: 5px;
    justify-content: flex-end;

    .ul {
      margin-right: 32px;

    .link {
      margin-right: 32px;
    }

    .link:last-child {
      margin-right: 0;
    }
    }
  }

  .nav-menu {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 25px;
    height: 25px;
    width: auto;
    filter: invert(100%);
  }

  .mobile-nav {
    padding: 20px;
    width: 40%;
    max-width: 125px;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    top: 44px;
    left: 0;
    background-color: #E6E6E6;
    opacity: 0.8;

    .link {
      padding: 15px 0;
      color: #312F2F;
    }
  }

  .nav-enter-from {
    transform: translateX(-250px);
  }

  .nav-enter-to {
    transform: translateX(0);
  }

  .nav-leave-from {
    transform: translateX(0);
  }

  .nav-leave-to {
    transform: translateX(-250px);
  }
  .nav-enter-active,
  .nav-leave-active {
    transition: all 0.6s ease;
  }
}
</style>
