<template>
  <div class="header">
    <div class="left">
      <big-card :post="post" />
    </div>
    <div class="right">
      <img :src="require(`../assets/images/${post.photo}.png`)" :alt="post.alt" />
    </div>
  </div>
  <div class="main-section">
    <small-card :post="post"/>
  </div>
</template>

<script>
import BigCard from '@/components/BigCard.vue';
import SmallCard from '@/components/SmallCard.vue';

export default {
  name: 'TestSection',
  components: { BigCard, SmallCard },
  props: ['post'],
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  width: 100%;
  padding-left: 70px;
  flex-direction: column;
  background-color: #B4D2BA;
  @media(min-width: 700px ) {
    min-height: 400px;
    max-height: 400px;
    flex-direction: row;
  }

  .left {
    display: flex;
    flex: 4;
    order: 1;
    width: 50%;
    max-width: 50%;
    padding-left: 50px;
    padding-top: 75px;
  }

  .right{
    order: 2;
    flex: 3;
    height: 500px;
  }

  img {
    display: block;
    padding-top: 125px;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px (0,0,0,0.06);
  }
}

.main-section {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  height: auto;
  padding-top: 150px;
  padding-left: 20px;
  padding-bottom: 40px;
}
</style>
