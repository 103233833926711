<template>
  <div class="wrapper" >
    <div class="section-title">
      Schedule A Demo
    </div>
    <div class="small-spacing"></div>
    <div class="contact_info">
      <div class="contact_pod">
        <div class="pod_icon">
          <img
            alt="Address Icon" class="icon"
            :src="require(`../assets/Icons/address.svg`)"/>
        </div>
        <div class="pod_header">Address</div>
        <div class="pod_info">
          <span>225 S. Swoope Avenue</span>
          <span>Suite 212</span>
          <span>Maitland, Fl 32751</span>
        </div>
      </div>
      <div class="contact_pod">
        <div class="pod_icon">
          <img
            alt="Phone Icon" class="icon"
            :src="require(`../assets/Icons/phone.svg`)"/>
        </div>
        <div class="pod_header">Phone Number</div>
        <div class="pod_info">
          <span>Toll Free: 1-800-642-6082</span>
          <span>Local: (407) 772-2100</span>
        </div>
      </div>
      <div class="contact_pod">
        <div class="pod_icon">
          <img
            alt="Email Icon" class="icon"
            :src="require(`../assets/Icons/email.svg`)"/>
        </div>
        <div class="pod_header">EMail</div>
        <div class="pod_info">
          <span>rgooden@softworxsolutions.com</span>
        </div>
      </div>
      <div class="contact_pod">
        <div class="pod_icon">
          <img
            alt="Website Icon" class="icon"
            :src="require(`../assets/Icons/website.svg`)"/>
        </div>
        <div class="pod_header">website</div>
        <div class="pod_info">
          <span>www.chirowrite.com</span>
        </div>
      </div>
    </div>
    <div class="small-spacing"></div>
    <div class="main_section">
      <div class="left">
        <div style="margin: 1rem 0; font-size: 1.5rem">
          <span v-if="submitted">
            Done, Email was sent! We will confirm your date & time shortly.
            <button @click="$router.push('/')">Continue</button>
          </span>
          <span v-if="error">
            Unexpected error: {{ setError }}
            <button @click="$router.push('/scheduledemo')">Try Again</button>
          </span>
          <span v-if="loading"> Email is being sent now... </span>
        </div>
        <div class="form_header" v-if="!submitted">Contact Us</div>
        <div class="small_spacing"></div>
        <form @submit.prevent="sendForm" v-if="!submitted">
          <span>
            <label for="name">
              <input type="text"
                placeholder="Enter Your Full Name"
                id="name" required
                v-model="form.chiroName"
              >
            </label>
          </span>
          <span>
            <label for="practice">
              <input type="text"
                placeholder="Enter The Name Of Your Practice"
                id="practice" required
                v-model="form.practiceName"
              >
            </label>
          </span>
          <span>
            <label for="phone">
              <input type="phone number"
                placeholder="Enter Your Phone Number"
                id="phone number" required
                v-model="form.phoneNumber"
              >
            </label>
          </span>
          <span>
            <label for="email">
              <input type="email"
                placeholder="Enter Your E-Mail Address"
                id="email" required
                v-model="form.emailAddress"
              >
            </label>
          </span>
          <span>
            <label for="date">
              <input type="datetime-local"
                id="date" required
                v-model="form.dateTime"
              >
            </label>
          </span>
          <span>Which product are you interested in?</span>
          <br>
          <div class="radio">
            <label for="Chiro Core">
              <input type="radio" id="Chiro Core" value="Chiro Core" v-model="form.softwareType">
              Chiro Core
            </label>

            <label for="Chiro Plus">
              <input type="radio" id="Chiro Plus" value="Chiro Plus" v-model="form.softwareType">
              Chiro Plus
            </label>

            <label for="Chiro Suite">
              <input type="radio" id="Chiro Suite" value="Chiro Suite" v-model="form.softwareType">
              Chiro Suite
            </label>
          </div>
          <div class="small-spacing"></div>
          <span>
            <button>Submit</button>
          </span>
        </form>
      </div>
      <div class="right">
        <img
          alt="Happy Female Chiropractor"
          :src="require(`../assets/images/doctor-spine.png`)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import UseEmail from '../js/UseEmail';

export default {
  setup() {
    const form = reactive({
      chiroName: '',
      practiceName: '',
      phoneNumber: '',
      emailAddress: '',
      dateTime: '',
      softwareType: '',
    });
    const endpointUrl = 'https://public.herotofu.com/v1/d5df7340-7c12-11ed-ae39-e5d94069e299';
    const {
      loading,
      submitted,
      error,
      sendEmail,
    } = UseEmail(endpointUrl);
    const sendForm = () => {
      sendEmail({
        Chiropractor: form.chiroName,
        Practice: form.practiceName,
        Email: form.emailAddress,
        PhoneNumber: form.phoneNumber,
        Date: form.dateTime,
        Software: form.softwareType,
      });
    };
    return {
      loading,
      submitted,
      error,
      sendForm,
      form,
      endpointUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 90%;
    padding: 30px;
    margin: 40px auto;
    text-align: center;
    justify-content: center;

  }

  .title {
    display: flex;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
  }

  .contact_info {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    justify-content: center;
  }

  .contact_pod {
    flex: 1;
    min-width:200px;
    width: 200px;
    height: 200px;
    border-radius: 6px;
    border: 2px solid #fff;
    box-shadow: inset 0px 1px 1px rgba(0,0,0, 0.33);

  }

  .pod_header {
    height: 50px;
    padding-top: 15px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }

  .pod_icon {
    padding-top: 15px;
    height: 50px;
  }

  .pod_info {
    height: 75px;

    span {
      display: block;
    }
  }

  .icon {
    height: 40px;
    width: auto;
  }

  .main_section {
    display: flex;
    flex-wrap: wrap;
  }

  .form_header {
    font-size: 18px;
    font-weight: bold;
  }

  .left {
    display: flex;
    flex-direction: column;
    width: 60%;
    min-width:400px;
    min-height: 400px;
    align-items: center;
    justify-content: center;

    span {
      display: block;
      box-sizing: border-box;
      padding: 5px;
    }
    input {
      display: block;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 100%;
      padding: 10px;
      border-radius: 6px;
      border: 2px solid #fff;
      box-shadow: inset 0px 1px 1px rgba(0,0,0, 0.33);
    }
  }
  .right {
    width: 39%;
    min-width: 300px;
    min-height: 400px;
    background-color: gray;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0 auto;
    }
  }

  .radio {
    display: flex;
    justify-content: space-between;
  }

  button {
    transition: 1s ease-in-out all;
    width: 120px;
    height: 40px;
    text-transform: uppercase;
    background-color: #c7cedb;
    border: .5px solid #312F2F;
    color: #312F2F;
    font-size: 12px;

    &:hover {
      background-color: #312F2F;
      color: #C7CEDB;
      border: .5px solid #312F2F;
    }
  }

</style>
