import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import FocusedView from '../views/TestView.vue';
import PricingView from '../components/PricingSection.vue';
import AboutView from '../views/AboutView.vue';
import ScheduleDemo from '../components/ScheduleDemo.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/info/:id',
    name: 'FocusedView',
    component: FocusedView,
    props: true,
    meta: {
      title: 'Notes',
    },
  },
  {
    path: '/pricing',
    name: 'PricingView',
    component: PricingView,
    props: true,
    meta: {
      title: 'Pricing',
    },
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView,
    props: true,
    meta: {
      title: 'About',
    },
  },
  {
    path: '/scheduledemo',
    name: 'ScheduleDemo',
    component: ScheduleDemo,
    props: true,
    meta: {
      title: 'Schedule A Demo',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Chirowrite`;
  next();
});
export default router;
