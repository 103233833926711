<template>
  <div class="card-container">
    <div class="card-small-header">{{ post.title }}</div>
    <div class="card-big-header">
      {{ post.overview }}<span class="accent">{{ post.overview2 }}</span>
    </div>
    <div class="button_position">
      <d-button @click="$router.push('/scheduledemo')" />
    </div>
  </div>
</template>

<script>
import DButton from '@/components/DarkButton.vue';

export default {
  components: { DButton },
  props: ['post'],
};
</script>

<style lang="scss" scoped>
.card-container {
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 240px;
  max-width: 290px;
  max-height: 400px;
  @media (min-width: 700px) {
    min-width: 450px;
    min-height: 200px;
    max-height: auto;
  }
}

.card-small-header {
  color: #263D42;
  filter: opacity(.7);
  font-size: 12px;
  padding-top: 10px;
  padding-left: 10px;
}

.card-big-header {
  color: #312F2F;
  font-size: 16px;
  font-weight: 500;
  padding-top: 40px;
  padding-left: 10px;

  .accent {
    font-weight: 700;
  }
}

.card-attention {
  color: #312F2F;
}

.button_position {
  position: absolute;
  bottom: 5px;
  left: 35%;
  cursor: pointer;
  visibility: hidden;
  @media(min-width: 750px) {
    visibility: visible;
  }
}
</style>
