<template>
  <div class="post-wrapper">
    <div class="post-content">
      <div>
        <div class="post-photo">
          <img :src="require(`../assets/images/${post.photo}.png`)" :alt="post.alt" />
        </div>
        <h2>{{ post.heading }}</h2>
        <p>{{ post.subHeading }}</p>
        <router-link class="link" :to="{ name: 'ScheduleDemo' }">
          Schedule A Demo Today
          <img
            src="@/assets/Icons/user-crown-light.svg"
            alt="Crown"
            class="arrow"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['post'],
};
</script>

<style lang="scss" scoped>
.post-wrapper {
  display: flex;
  flex-direction: column;
  @media (min-width: 700px) {
    min-height: 1080px;
    max-height: 1080px;
  }

  .post-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex: 4;
    order: 2;
    @media(min-width: 700px) {
      order: 1;
    }
    @media(min-width: 800px) {
      flex: 3;
    }

    div {
      max-width: 375px;
      padding: 72px 24px;
      @media(min-width: 700px) {
        padding: 0 24px;
        max-width: 550px;
        margin-top: -100px;
      }

      h2 {
        font-size: 32px;
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 24px;
        @media(min-width: 700px) {
          font-size: 40px;
        }
      }

      p {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.7;
        text-align: center;
      }

      .content-preview {
        font-size: 13px;
        max-height: 24px;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        padding-bottom: 4px;
        border-bottom: 1px solid transparent;
        transition: .5s ease-in all;

        &:hover {
          // border-bottom-color: #015D87;
          color: green;
          scale: (1.02);
        }
      }

      .link-light {
        &:hover {
          border-bottom-color: #fff;
        }
      }
    }
  }

  .post-photo {
    position: relative;
    width: 1440px;
    margin: 0 auto;
    order: 1;
    flex: 3;
    @media(min-width: 700px) {
      order: 2;
    }
    @media(min-width: 800px) {
      flex: 4;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0 auto;
      transition: 0.3s all ease-in-out;

      &:hover {
        transform:scale(1.01);
      }
    }
  }

  &:nth-child(even) {
    .post-content {
      order: 2;
    }
    .post-photo {
      order: 1;
    }
  }
}
</style>
