<template>
  <div class="card-container" :post="feature" v-for="(feature, x) in post.features"
      :key="x"
  >
    <div class="card">
      <div class="icon-area">
        <img
        :alt=" feature.feature " class="icon"
        :src="`../assets/Icons/${feature.icon}.png`"/>
      </div>
      <div class="heading-area"> {{ feature.feature }}</div>
      <div class="spacer-area"></div>
      <div class="description-area"> {{ feature.description }}</div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['post'],
  data() {
    return {
      icon: '',
    };
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  max-width: 450px;
  height: 250px;

  @media(min-width: 700px) {
    flex-direction: row;
  }
}

.icon {
  width: 22px;
  font-weight: 700;
  path {
    fill: #000;
  }
}

.card {
  display: grid;
  grid-template-columns: .5fr 1fr;
  grid-template-rows: .2fr 1fr;
  gap: 10px 10px;
  grid-template-areas:
  "iconArea headingArea"
  "spacerArea descriptionArea";
}

.icon-area {
  grid-area: iconArea;
}

.heading-area {
  grid-area: headingArea;
  min-width: 350px;
  font-weight: 600;
  text-transform: uppercase;
}

.spacer-area {
  grid-area: spacerArea;
}

.description-area {
  grid-area: descriptionArea;
  min-height: 125px;
}
</style>
