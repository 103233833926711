<template>
  <div id="app">
    <navigation-component />
    <router-view />
    <div class="small-spacing"></div>
    <footer-section />
  </div>
</template>

<script>
import FooterSection from '@/components/FooterSection.vue';
import NavigationComponent from '@/components/NavigatoinNew.vue';

export default {
  name: 'App',
  components:
    {
      NavigationComponent,
      FooterSection,
    },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@400;700&family=Quicksand:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Quicksand', sans-serif;
  color:#000;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.section-title {
  font-family: 'Quicksand', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 4;
  order: 2;
  font-size: 36px;
  padding-top: 40px;
  @media (min-width: 700px) {
    order: 1;
  }
  @media (min-width: 800px) {
    flex: 3;
  }
}

.center_button {
  padding: 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.link-light {
  color: #fff;
}

.arrow {
  margin-left: 8px;
  width: 12px;
  path {
    fill: #000;
  }
}

.arrow-light {
  path {
    fill: #fff;
  }
}

.button,
.router-button {
  transition: 500ms ease all;
  cursor: pointer;
  margin-top: 24px;
  padding: 12px 24px;
  background-color: #015D87;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: rgba(48, 48, 48, 0.7);
  }
}

.button-ghost {
  color: #000;
  padding: 0;
  border-radius: 0;
  margin-top: 50px;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  @media(min-width: 700px) {
    margin-top: 0;
    margin-left: auto;
  }

  i {
    margin-left: 8px;
  }
}

.button-light {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.button-inactive {
  pointer-events: none !important;
  cursor: none !important;
  background-color: rgba(128, 128, 128, 0.5) !important;
}

.post-card-wrap {
  position: relative;
  padding: 80px 16px;
  background-color: #f1f1f1;
  @media(min-width: 500px) {
    padding: 100px 16px;
  }

  .post-cards {
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr;
    @media(min-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media(min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.spacing {
  padding: 100px;
}

.small-spacing {
  padding: 20px;
}

.medium-spacing {
  padding: 50px;
}

</style>
