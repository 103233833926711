<template>
 <div class="d-button">
 </div>
</template>

<style lang="scss" scoped>
.d-button {
  transition: 1s ease-in-out all;
  display: flex;
  width: 120px;
  max-height: 50px;
  background-color: #312F2F;
  border: .5px solid #c7cedb;
  color: #c7cedb;
  font-size: 12px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #c7cedb;
    color: #312F2F;
    border: .5px solid #312F2F;
  }
}
</style>
