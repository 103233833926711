import { ref } from 'vue';

const UseEmail = (endpointUrl) => {
  const submitted = ref(false);
  const loading = ref(false);
  const error = ref();

  function sendEmail(data) {
    loading.value = true;
    submitted.value = false;
    error.value = undefined;

    fetch(endpointUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 422) {
          throw new Error('Are you a robot?');
        }

        if (response.status !== 200) {
          throw new Error(`${response.statusText} (${response.status})`);
        }

        return response.json;
      })
      .then(() => {
        submitted.value = true;
        loading.value = false;
      })
      .catch((err) => {
        error.value = err.toString();
        loading.value = false;
      });
  }

  return {
    submitted,
    loading,
    error,
    sendEmail,
  };
};
export default UseEmail;
