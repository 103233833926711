<template>
  <div class="home">
    <intro-section :post="welcomeScreen" />
    <post-section :post="post" v-for="(post, x) in posts" :key="x" class="home" />
  </div>
</template>

<script>
import { ref } from 'vue';
import PostSection from '@/components/PostSection.vue';
import IntroSection from '@/components/IntroSection.vue';

export default {
  name: 'HomeView',
  components: { PostSection, IntroSection },
  setup() {
    const posts = [
      {
        title: 'Notes',
        content:
          'An easy to use interface, utilizes an efficient screen layout, flow and navigation boxes. This will ensure that the time consuming task of documenting that you are faced with will be greatly reduced. Once the physical examination is complete, the doctor can immediately display a preview of the desired report on the screen, make the desired changes, save and print the final report. All reports are available for future retrieval and patient tracking.',
        photo: 'happy-female-chiro',
        alt: 'Happy female chiropractor',
      },
      {
        title: 'Scheduler',
        content:
          'The Chirowrite scheduler provides you and your staff with capability to run your office in the most efficient and effective manner. Customizable appointment templates and a multi- view interface allow you and your staff to create and view your schedule to suit your needs. ',
        photo: 'happy-male-chiro',
        alt: 'Happy male chiropractor',
      },
      {
        title: 'Billing',
        content:
          'The Chirowrite billing functionality provides complete billing services. Claims creation, generation of compliant EDI 5010 files, printouts of CMS1500 forms, posting of insurance checks, and saving EOBs for future reference. Customizable parameters allow for compliance of each insurance companies requirements.',
        photo: 'spine-study',
        alt: 'Chiropractor with a model of a spine',
      },
    ];

    const welcomeScreen = ref({
      heading: 'Streamline Your Practice',
      subHeading: 'Become More Organized, Efficient, and Profitable.',
      photo: 'chiropractor-patient-alt',
      alt: 'Chiropractor with a patient',
    });

    return {
      posts,
      welcomeScreen,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: 20px;
  margin-bottom: 20px;
}

.home:last-child {
  margin-bottom: 0;
}
</style>
